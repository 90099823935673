import React, { ChangeEvent, FC, useContext, useMemo, useState } from 'react';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { Form, Field } from 'react-final-form';
import { FormApi } from 'final-form';
import { TextField, DatePicker } from 'mui-rff';
import {
  debounce,
  Grid,
  IconButton,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
} from '@mui/material';
import { addDays } from 'date-fns';
import UndoIcon from '@mui/icons-material/Undo';
import { useSnackbar } from 'notistack';

import { createValidate, generateHwData } from './helperFunctions';
import { NewHwFormProps, FormData } from './interfaces';
import { BorrowFields } from './BorrowFields';
import { BasicFields } from './BasicFields';
import { TypesHardwareSelectField } from '../TypesHardwareSelectField';
import { OwnerSelectField } from '../OwnerSelectField';
import { CountUnitSelect } from '../CountUnitSelect';
import {
  UserContext,
  HardwareContext,
  OwnerContext,
  CompanyContext,
} from '../../contexts';
import {
  Company,
  HardwareRevisionType,
  HardwareType,
  PlacementType,
} from '../../commonTypes';
import {
  RevisionTypeSelectField,
  PlacementSelectField,
  PaymentDialog,
} from '../../components';
import { StyledButton } from '../../customStyledComponents';
import { isServerLicense } from '../../utils';

const HW_COUNT_MAX = 20;
const HW_COUNT_MIN = 1;

const changeInvNumbersToDefault = (
  defaultValues: string[],
  handleFieldChange: (name: string, value: string) => void,
  resetFieldState: (name: string) => void
) => {
  defaultValues.forEach((value, index) => {
    const fieldName = `inventoryNumber${index + 1}`;
    handleFieldChange(fieldName, value);
    resetFieldState(fieldName);
  });
};

export const NewHwForm: FC<NewHwFormProps> = (props) => {
  const { onCancel, onSubmit, printSticker, imageUploader } = props;

  const { userData } = useContext(UserContext);
  const { setHardwareUpdate } = useContext(HardwareContext);
  const { globalUserCompany = [], setCompanyUpdate } =
    useContext(CompanyContext);
  const { activeOwners = [] } = useContext(OwnerContext);

  const [isEditableInventoryNumber, setIsEditableInventoryNumber] =
    useState(false);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [detailCompany, setDetailCompany] = useState<Company>();
  const [expandInvNum, setExpandInvNum] = useState(false);
  const [expandSerialNum, setExpandSerialNum] = useState(false);
  const [typePrefix, setTypePrefix] = useState<string[]>([]);
  const [stickerPrint, setStickerPrint] = useState(false);
  const [isCompanyVATincluded, setCompanyVATincluded] = useState<boolean>();
  const [imageUploaderOpen, setImageUploaderOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleRevisionDate = async (
    values: FormData,
    revision: HardwareRevisionType | null
  ) => {
    let date = values.dateOfPurchase;
    let purchaseDate = date && new Date(date.getTime());
    var numberOfDaysToAdd = revision?.days;
    if (numberOfDaysToAdd && purchaseDate) {
      values.revisionNext?.setTime(
        addDays(purchaseDate, numberOfDaysToAdd).getTime()
      );
    }
  };

  const handleSubmit = async (
    values: FormData,
    form: FormApi<FormData, FormData>
  ) => {
    if (values.type?.smallEquipments === 'small') {
      values.inventoryNumber1 = undefined;
    }
    if (values.type?.smallEquipments === 'normal') {
      values.count = undefined;
      values.countUnit = undefined;
    }
    const body = generateHwData(values);
    if (body.length > HW_COUNT_MAX) {
      return;
    }

    try {
      const createHW = await fetch('/api/hardware', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'POST',
        body: JSON.stringify(body),
      });
      const res = await createHW.json();
      setHardwareUpdate(true);
      if (res.error) {
        if (typeof res.error === 'string') {
          enqueueSnackbar(res.error, {
            variant: 'error',
          });
          return;
        }
        if (createHW.status === 402) {
          if (values && values.companyId) {
            const detailCompanyFound = globalUserCompany.find(
              (val) => val._id === values.companyId
            );
            setDetailCompany(detailCompanyFound);
          }
          setShowPaymentDialog(!showPaymentDialog);
        }
        res.error.forEach(({ error }: { error: string }) => {
          enqueueSnackbar(error, { variant: 'error' });
        });
        return;
      }
      if (imageUploaderOpen) {
        imageUploader?.(res);
      }
      if (stickerPrint) {
        printSticker(res);
      }

      onSubmit(res);
      setTimeout(form.reset, 0);
    } catch (exception) {
      console.log(exception);
    }
  };

  const getSuggestedInventoryNumber = debounce(
    async (type?: HardwareType, count?: number) => {
      if (
        type?.inventoryPrefix &&
        count &&
        count >= HW_COUNT_MIN &&
        count <= HW_COUNT_MAX
      ) {
        const hwId = type._id;
        const dbTypes = await fetch(
          `/api/hardware/getSuggestedInventoryNumber/${hwId}?count=${count}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userData!.user.token}`,
            },
            method: 'GET',
          }
        ).then((data) => data.json());
        setTypePrefix(dbTypes.suggestion);
      }
    },
    300
  );

  const initialValues: FormData = useMemo(() => {
    const otherInvNums = typePrefix
      .slice(1)
      .reduce((existingInv, currItem, index) => {
        return {
          ...existingInv,
          [`inventoryNumber${index + 2}`]: currItem,
          [`borrowedDate${index + 2}`]: new Date(),
        };
      }, {});

    return {
      type: undefined,
      description: undefined,
      borrowedDate1: new Date(),
      companyId: activeOwners?.length === 1 ? activeOwners[0]?._id : undefined,
      borrow: false,
      revisionType: undefined,
      revisionNext: new Date(),
      inventoryNumber1: typePrefix[0],
      ...otherInvNums,
      hwCount: '1',
      count: '1',
      countUnit: 'pcs',
    };
  }, [typePrefix, activeOwners]);

  const handleResetInvNumberEdit = (
    isEditableInventoryNumber: boolean,
    type: HardwareType,
    hwCountNumber: number,
    typePrefix: string[],
    onChange: (name: string, value: string) => void,
    resetFieldState: (name: string) => void
  ) => {
    setIsEditableInventoryNumber(!isEditableInventoryNumber);
    getSuggestedInventoryNumber(type, hwCountNumber);
    changeInvNumbersToDefault(typePrefix, onChange, resetFieldState);
  };

  const [isPremium, setIsPremium] = useState(false);
  const isPremiumCheck = (company?: Company) => {
    const premiumDateValidTo = new Date(company?.licenceValidTo ?? '');
    const today = new Date();
    if (premiumDateValidTo >= today) {
      return setIsPremium(true);
    }
    return setIsPremium(false);
  };

  return (
    <Form
      keepDirtyOnReinitialize
      validate={createValidate}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      render={({
        handleSubmit,
        submitting,
        values,
        form,
        pristine,
        errors,
      }) => {
        const { type = {} as HardwareType, hwCount } = values;
        const hwCountNumber = parseInt(hwCount ?? '1');
        const dynamicFieldsArrayCount = Array.from(
          Array(hwCountNumber - 1).keys()
        );
        const hasOneOrLessItem = hwCountNumber <= 1;

        return (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container alignItems="flex-start" spacing={2}>
              <Grid item xs={12} sm={6} lg={12}>
                <Field name="companyId">
                  {(props) => (
                    <OwnerSelectField
                      value={props.input.value}
                      onChange={(company?: Company) => {
                        props.input.onChange(company?._id);
                        setCompanyVATincluded(company?.VATincluded);
                        isPremiumCheck(company);
                      }}
                      error={
                        props.meta.submitFailed ? props.meta.error : undefined
                      }
                    />
                  )}
                </Field>
              </Grid>

              <Grid item xs={12} sm={6} lg={12}>
                <Field name="type">
                  {(props) => (
                    <TypesHardwareSelectField
                      selectedValue={values.companyId}
                      value={props.input.value._id}
                      onChange={(type?: HardwareType) => {
                        props.input.onChange(type);
                        getSuggestedInventoryNumber(type, hwCountNumber);
                        if (type?.isSerialNumberRequired) {
                          setExpandSerialNum(true);
                        }
                      }}
                      error={
                        props.meta.submitFailed ? props.meta.error : undefined
                      }
                      showAddNew={!isServerLicense}
                    />
                  )}
                </Field>
              </Grid>

              {values.type?.smallEquipments === 'small' ? (
                <>
                  <Grid item xs={12} sm={6} lg={6}>
                    <Field name="count">
                      {(props) => (
                        <TextField
                          label={t('addHardwareForm.count')}
                          name="count"
                          margin="none"
                          fullWidth
                          required
                          disabled={!Boolean(type?._id)}
                          inputProps={{
                            inputMode: 'numeric',
                          }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6}>
                    <Field name="countUnit">
                      {(props) => {
                        return (
                          <CountUnitSelect
                            onChange={(countUnit?: string) => {
                              props.input.onChange(countUnit);
                            }}
                          />
                        );
                      }}
                    </Field>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sm={6} lg={12}>
                  <Field name="hwCount">
                    {(props) => (
                      <TextField
                        label={t('addHardwareForm.hwCount', {
                          value: HW_COUNT_MAX,
                        })}
                        name="hwCount"
                        margin="none"
                        fullWidth
                        required
                        disabled={!Boolean(type?._id)}
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                          min: HW_COUNT_MAX.toString(),
                          max: HW_COUNT_MAX.toString(),
                        }}
                        InputProps={{
                          onChange: (e: ChangeEvent<HTMLInputElement>) => {
                            const value =
                              e.target.value === ''
                                ? 1
                                : parseInt(e.target.value) ?? 0;
                            const hasValidValue =
                              value >= HW_COUNT_MIN && value <= HW_COUNT_MAX;

                            if (hasValidValue) {
                              props.input.onChange(e.target.value);
                              getSuggestedInventoryNumber(
                                type,
                                parseInt(e.target.value)
                              );
                            }
                          },
                          onFocus: (e) => {
                            e.target.select();
                          },
                        }}
                      />
                    )}
                  </Field>
                </Grid>
              )}

              <BasicFields type={type} />

              {values.type?.smallEquipments === 'small' ? null : (
                <>
                  <Grid item xs={12} sm={hasOneOrLessItem ? 6 : 12} lg={12}>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      display="flex"
                      spacing={2}
                    >
                      <Grid item xs={hasOneOrLessItem ? 9 : 12}>
                        {isEditableInventoryNumber ? (
                          <TextField
                            label={
                              <Trans i18nKey="addHardwareForm.inventaryNumber" />
                            }
                            type="text"
                            name="inventoryNumber1"
                            margin="none"
                            required={type.isInventoryNumberRequired}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  disablePointerEvents
                                  position="start"
                                  sx={{
                                    display: hasOneOrLessItem ? 'none' : 'flex',
                                  }}
                                >
                                  1.
                                </InputAdornment>
                              ),
                            }}
                          />
                        ) : (
                          <TextField
                            label={
                              <Trans i18nKey="addHardwareForm.inventaryNumber" />
                            }
                            name="inventoryNumber1"
                            disabled={!isEditableInventoryNumber}
                            inputProps={{
                              'data-testid': 'hw-inventory-number-field',
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  disablePointerEvents
                                  position="start"
                                  sx={{
                                    display: hasOneOrLessItem ? 'none' : 'flex',
                                  }}
                                >
                                  1.
                                </InputAdornment>
                              ),
                            }}
                          />
                        )}
                      </Grid>
                      {hasOneOrLessItem && (
                        <Grid
                          item
                          xs={3}
                          justifyContent="center"
                          alignItems="center"
                          display="flex"
                        >
                          {isEditableInventoryNumber ? (
                            <IconButton
                              onClick={() => {
                                handleResetInvNumberEdit(
                                  isEditableInventoryNumber,
                                  type,
                                  hwCountNumber,
                                  typePrefix,
                                  form.change,
                                  form.resetFieldState
                                );
                              }}
                            >
                              <UndoIcon />
                            </IconButton>
                          ) : (
                            <StyledButton
                              type="button"
                              variant="contained"
                              onClick={() => {
                                setIsEditableInventoryNumber(
                                  !isEditableInventoryNumber
                                );
                              }}
                              disabled={!values.type}
                            >
                              <Trans i18nKey="labels.editManually" />
                            </StyledButton>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {dynamicFieldsArrayCount.map((_, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={hasOneOrLessItem ? 6 : 12}
                      lg={12}
                      display={expandInvNum ? 'flex' : 'none'}
                    >
                      <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                        spacing={2}
                      >
                        <Grid item xs={hasOneOrLessItem ? 9 : 12}>
                          {isEditableInventoryNumber ? (
                            <TextField
                              label={
                                <Trans i18nKey="addHardwareForm.inventaryNumber" />
                              }
                              type="text"
                              name={`inventoryNumber${index + 2}`}
                              margin="none"
                              required={type.isInventoryNumberRequired}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    disablePointerEvents
                                    position="start"
                                  >
                                    {index + 2}.
                                  </InputAdornment>
                                ),
                              }}
                            />
                          ) : (
                            <TextField
                              label={
                                <Trans i18nKey="addHardwareForm.inventaryNumber" />
                              }
                              name={`inventoryNumber${index + 2}`}
                              disabled
                              inputProps={{
                                value: typePrefix[index + 1],
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    disablePointerEvents
                                    position="start"
                                  >
                                    {index + 2}.
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}

                  {dynamicFieldsArrayCount.length > 0 && (
                    <Grid
                      xs={12}
                      justifyContent="center"
                      alignItems="center"
                      display="flex"
                      paddingTop={1}
                    >
                      <Grid
                        item
                        xs={expandInvNum ? 3 : 4}
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                      >
                        <Button
                          variant="text"
                          color="primary"
                          size="small"
                          onClick={() => {
                            setExpandInvNum((prevValue) => !prevValue);
                          }}
                          sx={{
                            boxShadow: 'none',
                            width: '100%',
                          }}
                        >
                          {expandInvNum ? (
                            <Trans i18nKey="addHardwareForm.showLess" />
                          ) : (
                            <Trans i18nKey="addHardwareForm.showAll" />
                          )}
                        </Button>
                      </Grid>
                      <Grid
                        item
                        xs={3}
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                      >
                        {isEditableInventoryNumber ? (
                          <IconButton
                            onClick={() => {
                              handleResetInvNumberEdit(
                                isEditableInventoryNumber,
                                type,
                                hwCountNumber,
                                typePrefix,
                                form.change,
                                form.resetFieldState
                              );
                            }}
                          >
                            <UndoIcon />
                          </IconButton>
                        ) : (
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              !expandInvNum && setExpandInvNum(true);
                              setIsEditableInventoryNumber(
                                !isEditableInventoryNumber
                              );
                            }}
                            sx={{
                              boxShadow: 'none',
                              width: '100%',
                            }}
                            disabled={!values.type}
                          >
                            <Trans i18nKey="labels.editManually" />
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  <Grid item xs={12} sm={hasOneOrLessItem ? 6 : 12} lg={12}>
                    <TextField
                      label={<Trans i18nKey="addHardwareForm.serialNumber" />}
                      type="text"
                      name="serialNumber1"
                      margin="none"
                      required={type.isSerialNumberRequired}
                      inputProps={{
                        'data-testid': 'hw-serial-number-field',
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            disablePointerEvents
                            position="start"
                            sx={{ display: hasOneOrLessItem ? 'none' : 'flex' }}
                          >
                            1.
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {expandSerialNum && (
                    <>
                      {dynamicFieldsArrayCount.map((_, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={hasOneOrLessItem ? 6 : 12}
                          lg={12}
                        >
                          <TextField
                            label={
                              <Trans i18nKey="addHardwareForm.serialNumber" />
                            }
                            type="text"
                            name={`serialNumber${index + 2}`}
                            margin="none"
                            required={type.isSerialNumberRequired}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {index + 2}.
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                      ))}
                    </>
                  )}
                  {dynamicFieldsArrayCount.length > 0 && (
                    <Grid
                      xs={12}
                      justifyContent="center"
                      alignItems="center"
                      display="flex"
                      paddingTop={1}
                    >
                      <Button
                        type="button"
                        variant="text"
                        size="small"
                        onClick={() => {
                          setExpandSerialNum((prevValue) => !prevValue);
                        }}
                        sx={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                      >
                        {expandSerialNum ? (
                          <Trans i18nKey="addHardwareForm.showLess" />
                        ) : (
                          <Trans i18nKey="addHardwareForm.showAll" />
                        )}
                      </Button>
                    </Grid>
                  )}
                </>
              )}

              <Grid item xs={12} sm={hasOneOrLessItem ? 6 : 12} lg={12}>
                <TextField
                  label={
                    isCompanyVATincluded ? (
                      <Trans i18nKey="addHardwareForm.priceVATincluded" />
                    ) : (
                      <Trans i18nKey="addHardwareForm.priceWithoutVAT" />
                    )
                  }
                  type="number"
                  name="price"
                  margin="none"
                  required={type.isPriceRequired}
                  inputProps={{
                    'data-testid': 'hw-price-field',
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Field name="revisionType">
                  {(props) => (
                    <RevisionTypeSelectField
                      companyId={values.companyId}
                      value={props.input.value._id}
                      onChange={(revision: HardwareRevisionType | null) => {
                        props.input.onChange(revision);
                        handleRevisionDate(values, revision);
                      }}
                      error={
                        props.meta.submitFailed ? props.meta.error : undefined
                      }
                      disabled={!Boolean(values.companyId)}
                      showAddNew={!isServerLicense}
                    />
                  )}
                </Field>
              </Grid>
              <Grid item xs={12}>
                <Field name="placement">
                  {(props) => (
                    <PlacementSelectField
                      companyId={values.companyId}
                      value={props.input.value._id}
                      onChange={(placement: PlacementType | null) => {
                        props.input.onChange(placement);
                      }}
                      error={
                        props.meta.submitFailed ? props.meta.error : undefined
                      }
                      disabled={!Boolean(values.companyId)}
                      showAddNew={!isServerLicense}
                    />
                  )}
                </Field>
              </Grid>
              {values.revisionType ? (
                <Grid item xs={12}>
                  <DatePicker
                    inputFormat="dd.MM.yyyy"
                    name="revisionNext"
                    label={<Trans i18nKey="addHardwareForm.nextRevisionDate" />}
                    // @ts-ignore
                    inputProps={{ 'data-testid': 'hw-next-revision-field' }}
                  />
                </Grid>
              ) : null}

              <BorrowFields
                values={values}
                typePrefix={typePrefix}
                dynamicFieldsArrayCount={dynamicFieldsArrayCount}
              />

              {values.type?.smallEquipments === 'small' ? null : (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) => setStickerPrint(!stickerPrint)}
                      />
                    }
                    label={t('labels.print') as string}
                  />
                </Grid>
              )}
              {isPremium && !isServerLicense ? (
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={(e) =>
                          setImageUploaderOpen(!imageUploaderOpen)
                        }
                      />
                    }
                    label={t('labels.uploadFile') as string}
                  />
                </Grid>
              ) : null}

              <Grid
                item
                xs={12}
                position={{ xs: 'sticky', lg: 'relative' }}
                bottom={{ xs: '-16px', lg: '0' }}
                bgcolor={{ xs: '#fff' }}
                sx={{
                  marginTop: '16px',
                  zIndex: 2,
                }}
              >
                {!errors ||
                Object.keys(errors).length === 0 ||
                pristine ? null : (
                  <Grid
                    sx={{
                      color: 'red',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      padding: '16px',
                    }}
                  >
                    <Trans i18nKey="notifications.errorRequired" />
                  </Grid>
                )}

                <Grid
                  container
                  justifyContent="center"
                  sx={{
                    maxWidth: '100%',
                    width: '100%',
                    background: '#fff',
                  }}
                >
                  <Grid item xs={12} sm={6} lg={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting || pristine}
                      sx={{
                        boxShadow: 'none',
                        width: '100%',
                      }}
                      data-testid="create-new-hw-button"
                      onClick={() => {
                        if (values.type?.isSerialNumberRequired) {
                          setExpandSerialNum(true);
                        }
                        if (values.type?.isInventoryNumberRequired) {
                          setExpandInvNum(true);
                        }
                      }}
                    >
                      <Trans i18nKey="labels.send" />
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <Button
                      type="button"
                      variant="text"
                      onClick={onCancel}
                      sx={{
                        boxShadow: 'none',
                        width: '100%',
                      }}
                      data-testid="cancel-new-hw-button"
                    >
                      <Trans i18nKey="labels.cancel" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              {showPaymentDialog && detailCompany ? (
                <PaymentDialog
                  companyData={detailCompany}
                  onSubmit={() => {
                    setCompanyUpdate?.(true);
                    setHardwareUpdate(true);
                    setShowPaymentDialog(!showPaymentDialog);
                  }}
                  onClose={() => setShowPaymentDialog(!showPaymentDialog)}
                />
              ) : null}
            </Grid>
          </form>
        );
      }}
    />
  );
};
